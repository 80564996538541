html {
  scroll-behavior: smooth;
}
a {
  cursor: pointer !important;
}
.service-section .nav-link.tablinks {
  flex: 1;
  display: flex !important;
  text-align: center !important;
  align-items: center;
  align-self: center;
  justify-content: center;
  overflow: auto;
}

.service-section .nav-tabs .nav-item {
  width: auto;
  cursor: pointer;
  flex: 1;
  text-align: center;
  display: flex;
}

.service-section react-tabs__tab--selected {
  background: transparent linear-gradient(90deg, #913ce4 0%, #01a2fd 100%) 0% 0%
    no-repeat padding-box !important;
  border-color: #aaa;
  color: black;
  border-radius: 0 !important;
}

.ctm-row {
  padding: 30px 0;
}

.sec-img h3 {
  margin-bottom: 25px;
  text-align: center;
}

.benefit-sec h3 {
  margin-bottom: 25px;
}

.service-section .nav-tabs {
  width: 100% !important;
}
.service-section.cust_tab_sec .nav-tabs {
  overflow: auto;
}
.text-center {
  text-align: center !important;
}

.page-template-career
  .career-tabs
  .tab-link
  li.react-tabs__tab.react-tabs__tab--selected {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 20px;
  border: 1px solid #707070;
  border-bottom-color: rgb(112, 112, 112);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom: 2px solid #fff;
  margin-bottom: -1px;
}

ul.careertbs {
  margin-left: 25px;
}

.error {
  margin: 10px 0;
  color: red;
}

.success {
  margin: 10px 0;
  color: rgb(10, 51, 97);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 25px;
  font-family: "Cabin", sans-serif;

}

.ctm-container .react-tabs__tab--selected {
  background: transparent linear-gradient(90deg, #913ce4 0%, #01a2fd 100%) 0% 0%
    no-repeat padding-box;
}
.img-sstories {
  position: relative;
}
.img-sstories h4 {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: #fff;
  font-family: "Cabin", sans-serif;
}

.slick-slider li {
  list-style: none;
}

.single-services .page-banner img {
  min-height: auto;
  width: 100% !important;
}

.single-services .service-content-with-bgcolor.no-background img.full-img {
  width: 100%;
}

.page-banner img {
  width: 100% !important;
}

.form-loader,
.homepage-loader {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 99;
}

.form-loader-div,
.homepage-loader-div {
  background: #fff;
  height: 100vh;
}

.success-and-blog .img-wrap {
  position: relative;
}
.success-and-blog .img-wrap h4 {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: #fff;
  font-family: "Cabin", sans-serif;
}

.page-template-contact .common-form .wrapper select {
  color: #111;
}

.page-template-contact .common-form .wrapper option:disabled {
  color: #ddd !important;
}

.success-tab .react-tabs__tab,
.success-tab .react-tabs__tab-list {
  border: none;
  margin-bottom: 1px;
}

.success-tab .react-tabs__tab:focus::after {
  background: transparent;
}

.success-tab .react-tabs__tab--selected {
  background: #0e2b5c;
  color: #fff !important;
}

.success-tab .react-tabs__tab--selected img {
  opacity: 0;
}

.success-tab .react-tabs__tab--selected img.active {
  opacity: 1 !important;
}
@media (max-width: 991px) {
  .slick-prev::before,
  .slick-next::before {
    display: none;
  }
  .services_area .service_box{
    padding: 25px 25px 25px !important;
  }
}

@media(max-width:767px) {
  nav .new_primary_menu li .new_sub_menu li a:hover{
     background: linear-gradient(90deg, rgba(3,73,131,1) 0%, rgba(3,73,128,1) 20%, rgba(5,0,38,1) 100%);
  }
  nav .new_sub_menu{
    width: 100%;
  }
  .job-opp h2 {
    font-size: 18px !important;
  }
  .job-opp p {
    font-size: 13px !important;
  }

  .job-open-btn{
    font-size: 13px !important;
  }
  .job-open-btn span{
    font-size: 22px !important;
  }
  .social-share {
    position: relative !important;
    top: -7px !important;
    right: -15px !important;
    display: table;
  }
  .accordion__button::before {
    top: 40px !important;
  }
}

.footer-bottom {
  background-color: #0e2b5c;
}

.hiddebfield {
  visibility: hidden;
  height: 0;
}

.ifreme-page {
  position: fixed;
  top: 95px;
  width: 100%;
  left: 0;
}

.no-background-clr {
  background: transparent;
}
.techno-image img {
  width: 100% !important;
}

.midservice {
  margin: auto !important;
}

.rfinfo {
  margin: 0 auto;
  display: table;
  text-align: center;
  border: 1px solid #fff;
  padding: 21px 35px;
  border-radius: 5px;
  transition: 0.5s;
}

.rfinfo:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #034983;
  transition: 0.5s;
}

.newjobs {
  font-family: "Cabin", sans-serif;
  color: #6f6f6f;
  font-size: 14px;
}

.newjobs ul {
  list-style-type: disc;
  padding: 0 20px;
  font-size: 14px;
}
.newjobs li {
  margin-bottom: 7px;
  font-size: 14px;
}
.newjobs h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.newjobs .accordion__button {
  font-weight: bold;
  position: relative;
  font-size: 20px;
}

.npd {
  padding: 0 !important;
}

.marketing-bnr {
  display: block;
}

.newjobs p {
  line-height: 22px;
}

.newjobs li {
  line-height: 22px;
}

.newjobs strong {
  font-weight: bold;
}

.service.cust_com_sec.lazyloaded {
  font-family: 'Montserrat', sans-serif;
}
.page-template-service .service h3 {
  font-family: "Cabin", sans-serif;
  font-size: 27px;
}
body{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.page-template-home .service h3 {
  font-size: 27px;

}

.cust_com_sec.company-section span{
  font-weight: bold;
}
.cust_com_sec.company-section h3 {
  color: #6f6f6f;
  font-size: 25px;
}

.page-template-home .service h3 {

  color: #6F6F6F;

}

.wrapper.privacy-policy-pg {
  font-family: "Cabin", sans-serif;
  color: #636363;
}

.privacy-policy-pg h1 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}
.privacy-policy-pg p{
  font-size: 14px;
  line-height: 16px;
}

.privacy-policy-pg ul{
  font-size: 14px;
  list-style-type: disc;
padding: 15px;
}


.privacy-policy-pg li{
  font-size: 14px;
  margin-bottom: 5px;
}

.privacy-policy-pg li a{
  font-size: 14px;
}


.privacy-policy-pg  strong {
  font-weight: bold;

  line-height: 22px;
}
.privacy-policy-pg h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.ourval-slider .slick-slide img {
  display: block;
  max-width: 108px;
  margin: 30px auto;
}

.ourval-slider .slick-slide p {
font-size: 18px;

}

.ourval-slider .slick-slide {
text-align: center;
  }

  .ourval-slider .slick-dots{
    bottom: -85px;
  }
  .ourval-slider .slick-dots li.slick-active button::before {
    opacity: .75;
    color: #fff;
  }

  .ourval-slider .slick-dots li button::before {
    color: #fff;
  }

  .ourval-slider .slick-arrow {
    display: none !important;
  }


  

.career_list:nth-child(even){
  background: #F0F8FF;
}

.career_row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 0 60px;
  flex-wrap: wrap;
}

.career_list:nth-child(even) .career_row{
  flex-direction: row-reverse;
}

.career_row .career_left_sec{
  flex: 0 0 100%;
  max-width: calc(100% - 500px);
}

.career_list:nth-child(odd) .career_row .career_left_sec{
  padding-left: 50px;
}


.career_list:nth-child(even) .career_row .career_left_sec{
  padding-right: 50px;
}

.career_row .career_left_sec .career_content h2{
  font-size: 30px;
  line-height: 50px;
  color: #707070;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.career_row .career_left_sec .career_content p{
  font-size: 20px;
  line-height: 30px;
  color: #707070;
  font-family: "Cabin", sans-serif;
  font-weight: 400;
}

.career_row .career_right_sec{
  flex: 0 0 500px;
  max-width: 500px;
} 

.career_row .career_right_sec img{
  width: auto;
}

.career_list:nth-child(odd) .career_row .career_right_sec img{
  /* margin-left: auto; */
}

@media (max-width: 991px){
  .career_list:nth-child(odd) .career_row .career_left_sec{
    padding-right: 80px;
  }
  
  
  .career_list:nth-child(even) .career_row .career_left_sec{
    padding-left: 80px;
  }

  .career_row .career_left_sec .career_content h2{
    font-size: 24px;
    line-height: 40px;
  }
  
  .career_row .career_left_sec .career_content p{
    font-size: 16px;
    line-height: 26px;
  }
  .career_row .career_left_sec,
  .career_row .career_right_sec{
    flex: 0 0 100%;
    max-width: 100%;
    /* width: 100%; */
  }
  .career_img img{
    margin: 20px auto 20px;
  }
  
  .career_list:nth-child(even) .career_row{
    flex-direction:column-reverse
  }

  .career_list .career_row{
    flex-direction:column-reverse
  }
  
}

@media (max-width: 767px){
  .career_row .career_left_sec,
  .career_row .career_right_sec{
    flex: 0 0 100%;
    max-width: 100%;
    /* width: 100%; */
  }

  .career_list:nth-child(even) .career_row{
    flex-direction:column-reverse
  }

  .career_list .career_row{
    flex-direction:column-reverse
  }

  .career_row .career_right_sec img{
    width: 100%;
    max-width: 200px;
  }

  .career_list:nth-child(odd) .career_row .career_left_sec{
    padding-right: 0px;
  }
  
  .career_list:nth-child(even) .career_row .career_left_sec{
    padding-left: 0px;
  }

  .career_list .career_row .career_left_sec{
    padding-top: 30px;
  }

  .career_list:nth-child(odd) .career_row .career_right_sec img{
    margin-left: unset;
  }

  .career_list .career_row .career_right_sec img{
    margin-right: auto;
  }

  .career_row{
    padding: 50px 0 30px;
  }
  .career_list:nth-child(2n+1) .career_row .career_left_sec {
    padding-left: 0;
  }
  .career_list:nth-child(2n) .career_row .career_left_sec {
    padding-right: 0;
  }
}

strong {
  font-weight: bold;
}

.wrapper.newjobs {
  padding: 15px;
  background: #F0F8FF;
}

.accordion__button {
  background-color: transparent !important;
 
}
.accordion {
  border:none !important;
  border-radius: 2px;
}

.accordion__button::before {

  position: absolute;
  right: 0;
  top: 23px;
}

.accordion__button {
  background-color: #f4f4f4;
  color: #707070;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.appl-btn {
  background: transparent linear-gradient(90deg, #913CE4 0%, #01A2FD 100%) 0% 0% no-repeat padding-box;
  border: none;
  padding: 10px 15px;
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  width: 125px !important;
  text-align: left;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  box-shadow: -1px 6px 9px #8e8a8a82;
}

.ReactModalPortal h2 {
  font-size: 22px;
  /* border-bottom: 1px solid #ddd; */
  padding: 0 10px 10px 0;
  margin-bottom: 15px;
  font-family: "Cabin", sans-serif;
  font-weight: bold;
}

.ReactModalPortal button {
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Cabin", sans-serif;
}

.ReactModalPortal input {
  display: block;
  width: 100%;
  border: none;
    border-bottom-width: medium;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
  /* border-bottom: 1px solid #ddd; */
  margin-bottom: 5px;
  /* padding: 10px; */
  font-family: "Cabin", sans-serif;
  font-size: 14px;
}


.ReactModalPortal textarea {
  display: block;
  width: 100%;
  border: none;
    border-bottom-width: medium;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
  /* border-bottom: 1px solid #ddd; */
  /* margin-bottom: 15px; */
  /* padding: 10px; */
  font-family: "Cabin", sans-serif;
  font-size: 14px;
}


.clz-modal {
  border: none;
  background: no-repeat;
  padding: 10px;
  font-size: 16px;
  opacity: .3;
  cursor: pointer;
}
.clz-modal:hover {
 
  opacity: 1;

}

.ChooseFile input{
  padding-left: 0 !important;
}

.arr-icon {
  position: absolute;
  right: 17px;
  font-weight: bold;
  top: 4px;
  font-size: 18px;
  transition: 0.5s;
}

.appl-btn:hover .arr-icon{
  right: 10px !important; 
  transition: 0.5s;
}

.appl-btn.appl-submit {
  text-align: center;
  margin: 0 auto;
}
.job-open-btn {
  padding: 20px 100px 20px 20px;
  font-size: 22px;
  color: #051440;
  border: none;
  background: #fff;
  border-radius: 4px;
  margin-top: 22px;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}
.job-open-btn span{
  position: absolute;
right: 41px;
font-weight: bold;
top: 12px;
font-size: 31px;
transition: 0.5s;
}

.job-open-btn:hover{

  box-shadow: -1px 6px 9px #29292982;
}

.job-open-btn:hover span{
  right: 20px !important; 
  transition: 0.5s;

}


.scrolltojobs {
  margin: -150px;
  padding: 150px;
  visibility: hidden;
}
.available-positions {
  font-family: "Cabin", sans-serif;
  font-size: 26px;
  font-weight: bold;
  color: #444;
  margin-bottom: 20px;
}

.lbl100{
  display: block;
  margin-bottom: 10px;
  /* font-weight: bold; */
}

.wh-hus {
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  border-left: none;
  border-top: none;
  border-right: none;
  font-family: "Cabin", sans-serif;
  width: 100%;
  display: block;
}

.otherreason{
  margin-bottom: 0 !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
}

.career-tabs{
  overflow-x: hidden;
}

.newjobs .ql-align-center {
  text-align: center;
}
.newjobs .ql-align-right {
  text-align: right;
}

.newjobs .ql-align-justify {
  text-align: justify;
}
.newjobs ol, .newjobs ul {
  padding-left: 1.5em;
}

.newjobs ol{
  list-style-type: decimal !important;
  list-style-position: inside !important;
}

.newjobs ul {
  list-style-type: disc !important;
  list-style-position: inside !important;
}

.half-column > div {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.half-column > div > div {
  width: 100%;
}

.txt-area-full > div {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.txt-area-full > div > div {
  width: 100%;
  padding: 10px 0;
  border-radius: 0;
}

.txt-area-full {
  margin-top: 15px;
}

.other-field> div > div {
  width: 100%;
}

.other-field> div {
  width: 100%;
}

.MuiFormLabel-asterisk , .astrik-mrk{
  color: red !important;
}

.wpcf7-form.init.box {
  font-family: "Cabin", sans-serif;
}   

.lbl-grey{
  color: rgba(0, 0, 0, 0.6);
}

.txt-area-full .MuiOutlinedInput-notchedOutline {
  border-top: 0;
  border-bottom: 1px solid #888;
  border-left: 0;
  border-right: 0;
}

.txt-area-full #outlined-multiline-static-label{
  left: -15px;
}
.accordion__panel{
padding-top: 0 !important;
}


.social-share {
  position: absolute;

  top: 12px;

  right: 45px;

}

.accordion__item{
  position: relative;
}

.react-share__ShareButton {
  margin: 0 2px;
}

.job-l {
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent !important;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
}

.flag-dropdown {
  width: auto !important;
}

.search-box {
  margin: 0 !important;
  width: 96% !important;
}

.search-emoji {
  display: none;
}

.react-tel-input .form-control {
  width: 100% !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-top: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-left: none !important;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-bottom: none !important; 
  border-top: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-right: 0 !important;
}

.react-tel-input .form-control:focus {
  width: 100% !important;
  border-bottom: 2px solid #1976d2 !important;
  border-top: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-left: none !important;
}

.selected-flag.open{
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0 !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0 !important;
}

.handbook-policy {
  width: 100%;
  height: 100vh;
}